import { createApp } from 'vue'
import Vuex from 'vuex'
import setting from './setting/index'
createApp(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    setting
  },
  state: {
    changed: false
  },
  mutations: {
    changed: (state) => state.changed = !state.changed,
    setUser(state, user) {
      state.user = user;
    },
    setNewPosts(state){
      
    },
    setToken(state, token) {
      window.localStorage.setItem('token', token)
      state.token = token;
    },
    logout(state){
      window.localStorage.removeItem('token')
    }
  },
  actions: {
  },
  getters: {
    changed: (state) => state.changed,
    isAuthenticated(){
      return window.localStorage.getItem("token");
    },
    getToken(state){
      return state.token;
    },
    isLoggedIn(state) {
      return !!state.token;
    },
  },
  strict: debug
})
